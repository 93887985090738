import React from "react"
import { StaticImage } from "gatsby-plugin-image"
// import { CakeIcon, CheckCircleIcon, ClipboardCheckIcon } from '@heroicons/react/solid'


export default function Sidebar() {
  return (
            <div className="bg-white ">

                    <div className="rounded-lg bg-white shadow flex flex-col flex-grow text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                      <div
                        className="text-left mb-4 text-xl font-bold"
                      >
                        <p>A propos de l'auteur</p>
                      </div>
                      <a href="/praticien/jean-michel-schlupp-8/">
                        <div className="mx-auto h-40 w-40 rounded-full">
                            <StaticImage
                              className="mx-auto h-40 w-40 rounded-full"
                              src="../../markdown-pages/praticien/jean-michel-schlupp-8/image.png" 
                              alt=""
                            />
                        </div>
                        <h3 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">Jean-Michel SCHLUPP</h3>
                        <p className="mt-2 text-base leading-7 text-gray-600">
                        Psychanalyste - Praticien en soins hospitaliers de support - Directeur pédagogique
                        </p>
                      </a>
                      <div className="mt-8">
                        <a
                          href="/praticien/jean-michel-schlupp-8/"
                          className="inline-flex items-center rounded-md ring-1 ring-spring-bleu bg-spring-bleu px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                          Voir le membre
                        </a>
                      </div>
                    </div>
                    
            </div>
        )
    }